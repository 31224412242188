import { CSSProperties } from 'react';
import { useSettingsStore } from 'src/hooks/store/useSettingsStore';
import { getPalette } from 'src/theme/palette';
import { getListItemKey } from 'src/utils/format';

// ----------------------------------------------------------------------

export default function Skeleton({
  count = 1,
  width = '100%',
  height = 120,
  style
}: {
  count?: number;
  width?: number | string;
  height?: number | string;
  style?: CSSProperties;
}) {
  const themeMode = useSettingsStore();
  const palette = getPalette(themeMode);
  return (
    <div className="flex w-full flex-col">
      {Array(count)
        .fill(0, 0, count)
        .map((item, idx) => {
          return (
            <div
              key={getListItemKey(item + idx)}
              className="animate-pulse rounded-lg"
              style={{
                width: width,
                height: height,
                backgroundColor: palette.background.skeleton,
                marginTop: count > 0 && idx !== 0 ? '0.5rem' : 0,
                ...style
              }}
            ></div>
          );
        })}
    </div>
  );
}
