import { cn } from '@/lib/utils';
import { Search } from 'lucide-react';
import { CSSProperties } from 'react';
import Input from 'src/components/core/atoms/Input';

export default function CommandInput({
  searchValue,
  setSearchValue,
  placeholder = 'Search...',
  size = 'sm',
  disabled = false,
  className,
  style,
  containerStyle
}: {
  searchValue: string;
  setSearchValue: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  size?: 'sm' | 'default';
  className?: string;
  containerStyle?: CSSProperties;
  style?: CSSProperties;
}) {
  return (
    <div
      className="flex flex-nowrap items-center bg-popover"
      style={containerStyle}
    >
      <Search className="ml-sm h-4 w-4" />
      <Input
        value={searchValue}
        disabled={disabled}
        onChange={(e) => setSearchValue(e.target.value)}
        className={cn(
          className,
          'border-0 bg-popover shadow-none',
          size === 'sm' ? 'body2 h-small-button' : ''
        )}
        style={style}
        placeholder={placeholder}
        noBorder
      />
    </div>
  );
}
